import ApiService from "@/core/services/api.service";
// import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
  collection2: []
};

const getters = {
  stockItemCollection(state) {
    return state.collection;
  },

  getStockItemByID: (state) => (id) => {
    return state.collection2.find((item) => item.id == id);
  },

  stockItemModCollection(state) { // Add this getter
    return state.collection2;
  },
};

const actions = {
  fetchStockItemMod(context) { // Add the action name
    ApiService.get("stock/item")
      .then(({ data }) => {
        context.commit("fetchStockItemMod", data); // Commit the data
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
  
  fetchStockItem(context) {
    // let appLocale = i18nService.getActiveLanguage()
    ApiService.get("stock/itemEntity")
      .then(({ data }) => {
        // create localized fields
        // let dataLocalized = data.map((item) => {
        //     if (item.translations.length == 0) {
        //         item.name = "Name not defined"
        //         return item
        //     }

        //     if (item.translations[appLocale]) {
        //         item.name = item.translations[appLocale].name
        //     } else {
        //         item.name = item.translations[Object.keys(item.translations)[0]].name;
        //     }

        //     return item
        // })

        context.commit("fetchStockItem", data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  fetchStockItem(state, data) {
    state.collection = data;
  },

  fetchStockItemMod(state, data) { // Add this mutation
    state.collection2 = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
